import $ from 'jquery';
import PageHome from "./pages/page-home";
import PageNews from "./pages/page-news";
import PageNewsPost from "./pages/page-news-post";
import PageAbout from "./pages/page-about-us";
import PageLandingpage from "./pages/page-landingpage";
import PageSolutions from "./pages/page-solutions";
import PageProjects from "./pages/page-projects";
import PageProjectsEntry from "./pages/page-projects-entry";
import PageContact from "./pages/page-contact";
import PageGeneric from "./pages/page-generic";
import PagePress from "./pages/page-press";
import PageAdVideo from "./pages/page-ad-video";
import PageDsrAds from "./pages/page-dsr-ads";
import PageKnowHow from "./pages/page-know-how";
import PageDsrAi from './pages/page-dsr-ai'

import ContactSubpages from "./sections/contact-subpages";
import CompassNavigation from "./sections/compass-navigation";
import LanguageSwitch from "./sections/language-switcher";
import MoveInView from "./sections/move-in-view";

import PictureSlider from "./objects/picture-slider";
import Accordion from "./objects/accordion";
import ContentTabs from "./objects/content-tabs";
import Header from "./objects/header";

$(document).ready(() => {
    (new Header()).init();
    (new PageHome()).init();
    (new PageAbout()).init();
    (new PageLandingpage()).init();
    (new PageSolutions()).init();
    (new PageProjects()).init();
    (new PageProjectsEntry()).init();
    (new PageNews()).init();
    (new PageContact()).init();
    (new PageNewsPost()).init();
    (new PageGeneric()).init();
    (new PagePress()).init();
    (new PageAdVideo()).init();
    (new PageDsrAds()).init();
    (new PageKnowHow()).init();
    (new ContactSubpages().init());
    (new CompassNavigation()).init();
    (new LanguageSwitch()).init();
    (new PictureSlider()).init();
    (new MoveInView()).init();
    (new Accordion()).init();
    (new ContentTabs()).init();
    (new PageDsrAi().init())

    document.querySelectorAll('.cookie-consent-link').forEach((el) => {
        el.setAttribute('href', 'javascript:Cookiebot.renew()');
    })
});
